<template>
    <div class="h-full h-handle-table">
        <div class="h-table-list">
            <div slot="buttonGroup" class="h-handle-button">
                <div class="h-b">
                    <el-button type="primary" size="small" @click="add"
                        v-right-code="'Productclassify:Edit'">新增</el-button>
                </div>
                <div class="h-b">
                    <el-button type="primary" size="small" @click="deleteClassfiy"
                        v-right-code="'Productclassify:Delete'">删除</el-button>
                </div>
            </div>
            <p slot="elList">
                <el-table slot="elList" border highlight-current-row default-expand-all ref="tableDom" row-key="Id"
                    :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
                    style="width: 100%;margin-bottom: 20px;" :data="dataSource.data || []" @selection-change="selectChange">
                    <el-table-column type="selection" width="55" reserve-selection  v-if="!config.isDetailDisplay"></el-table-column>
                    <el-table-column v-for="(col,index) in theadData" :key="col.key" :prop="col.prop" :label="col.label"
                    :fixed="index==0&&(!config||!config.isDetailDisplay)"
                    :min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
                    v-if="!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<2)"
                    :align="col.align || 'center'" :width="col.width || ''">
                        <template slot-scope="scope">
                            <span>{{ scope.row[col.prop] }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" fixed="right" align="center" v-if="!config.isDetailDisplay">
                        <template slot-scope="scope">
                            <el-button type="text" v-right-code="'Productclassify:Edit'"
                                @click="rowClick(scope.row)">编辑</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        this.Event.$on("reloadTreesList", () => this.getClassifyList());
        this.Event.$on("onAddClassify", () => this.add());
        this.getClassifyList();
    },
    data() {
        return {
            dataSource: {
                data: [],
            },
            multipleSelection: [],
            theadData: [
                { label: "分类名称", prop: "label", align: "left" },
                { label: "分类编码", prop: "Code" },
                { label: "分类备注", prop: "Remark", align: "left", width: "300px" },
                {
                    label: "创建时间",
                    prop: "CreatedTime",
                    fieldType: 6,
                    width: "150px",
                },
                { label: "创建人", prop: "CreatedUserName" },
            ],
        };
    },
    props: {
        config: {
            isDetailDisplay: false,
            isButtonlDisplay: false,
        },
    },
    methods: {
        rowClick(row) {
            var _this=this;
            this.$ajax.send("omsapi/productclassify/get", "get", { id: row.Id }, (data) => {
                _this.Event.$emit("clearEditClassifyForm");
                _this.onChangeEditDataSource(data.Result);
            });
        },
        onChangeEditDataSource(data) {
            this.config.isDetailDisplay = true;
            this.$emit("onChangeEditDataSource", data);
        },
        add() {
            var _this=this;
            this.$ajax.send("omsapi/productclassify/getEmpty", "get", {}, (data) => {
                _this.onChangeEditDataSource(data.Result);
            });
        },
        deleteClassfiy() {
            if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                this.Utils.messageBox(`未选商品分类.`, "error");
                return false;
            }
            var ids = this.Utils.selectionsToArr(this.multipleSelection, "Id");
            var _this = this;
            this.$confirm('确定要删除吗？').then(_ => {
                _this.$ajax.send("omsapi/productclassify/delete", "post", ids, (data) => {
                    if(data.IsSuccess){
                        _this.Utils.messageBox("删除成功.", "success");
                        _this.getClassifyList();
                    }
                });
            }).catch(_ => { });
        },
        selectChange(val) {
            this.multipleSelection = val;
            this.$emit("onCheckRow", this.multipleSelection);
        },
        getClassifyList() {
            var _this = this;
            this.$ajax.query("omsapi/productclassify/trees", "get", {}, (data) => {
                _this.dataSource.data = data.Result;
            });
        },
    },
};
</script>